import * as React from 'react'
import styled from '@emotion/styled'

import { widths, dimensions, mediaqueries } from '../styles/variables'

export interface ContainerProps {
  children: React.ReactNode
  className?: string
}

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: ${widths.lg}rem;
  padding: 1.5rem;

  ${mediaqueries.md} {
    padding: ${dimensions.containerPadding.lg}rem;
  }
`

const Container: React.FC<ContainerProps> = ({ children, className }) => <StyledContainer className={className}>{children}</StyledContainer>

export default Container
